// Copyright 2021-2023 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

// @generated by protoc-gen-es v1.4.2 with parameter "target=ts,import_extension=none"
// @generated from file kubeappsapis/core/packages/v1alpha1/repositories.proto (package kubeappsapis.core.packages.v1alpha1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Any, Message, proto3 } from "@bufbuild/protobuf";
import { Context } from "./packages_pb";
import { Plugin } from "../../plugins/v1alpha1/plugins_pb";

/**
 * AddPackageRepositoryRequest
 *
 * Request for AddPackageRepository
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.AddPackageRepositoryRequest
 */
export class AddPackageRepositoryRequest extends Message<AddPackageRepositoryRequest> {
  /**
   * The target context where the package repository is intended to be
   * installed.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * A user-provided name for the package repository (e.g. bitnami)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * A user-provided description. Optional
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * Whether this repository is global or namespace-scoped. Optional.
   * By default, the value is false, i.e. the repository is global
   *
   * @generated from field: bool namespace_scoped = 4;
   */
  namespaceScoped = false;

  /**
   * Package storage type
   * In general, each plug-in will define an acceptable set of valid types
   * - for direct helm plug-in valid values are: "helm" and "oci"
   * - for flux plug-in valid values are: "helm" and "oci". In the
   *   future, we may add support for git and/or AWS s3-style buckets
   *
   * @generated from field: string type = 5;
   */
  type = "";

  /**
   * A URL identifying the package repository location. Must contain at
   * least a protocol and host
   *
   * @generated from field: string url = 6;
   */
  url = "";

  /**
   * The interval at which to check the upstream for updates (in time+unit)
   * Some plugins may, additionally, support other formats, for instance,
   * a cron expression.
   * e.g., "*\/10 * * * *" will be equivalent to "10m"
   * Optional. Defaults to 10m if not specified
   *
   * @generated from field: string interval = 7;
   */
  interval = "";

  /**
   * TLS-specific parameters for connecting to a repository. Optional
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig tls_config = 8;
   */
  tlsConfig?: PackageRepositoryTlsConfig;

  /**
   * authentication parameters for connecting to a repository. Optional
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth auth = 9;
   */
  auth?: PackageRepositoryAuth;

  /**
   * The plugin used to interact with this package repository.
   * This field should be omitted when the request is in the context of a
   * specific plugin.
   *
   * @generated from field: kubeappsapis.core.plugins.v1alpha1.Plugin plugin = 10;
   */
  plugin?: Plugin;

  /**
   * Custom data added by the plugin
   * A plugin can define custom details for data which is not yet, or
   * never will be specified in the core AddPackageRepositoryRequest
   * fields. The use of an `Any` field means that each plugin can define
   * the structure of this message as required, while still satisfying the
   * core interface.
   * See https://developers.google.com/protocol-buffers/docs/proto3#any
   * Just for reference, some of the examples that have been chosen not to
   * be part of the core API but rather plugin-specific details are:
   *   direct-helm:
   *      - image pull secrets
   *      - list of oci repositories
   *      - filter rules
   *      - sync job pod template
   *
   * @generated from field: google.protobuf.Any custom_detail = 11;
   */
  customDetail?: Any;

  constructor(data?: PartialMessage<AddPackageRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.AddPackageRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "namespace_scoped", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "interval", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "tls_config", kind: "message", T: PackageRepositoryTlsConfig },
    { no: 9, name: "auth", kind: "message", T: PackageRepositoryAuth },
    { no: 10, name: "plugin", kind: "message", T: Plugin },
    { no: 11, name: "custom_detail", kind: "message", T: Any },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AddPackageRepositoryRequest {
    return new AddPackageRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AddPackageRepositoryRequest {
    return new AddPackageRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AddPackageRepositoryRequest {
    return new AddPackageRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddPackageRepositoryRequest | PlainMessage<AddPackageRepositoryRequest> | undefined,
    b: AddPackageRepositoryRequest | PlainMessage<AddPackageRepositoryRequest> | undefined,
  ): boolean {
    return proto3.util.equals(AddPackageRepositoryRequest, a, b);
  }
}

/**
 * PackageRepositoryTlsConfig
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig
 */
export class PackageRepositoryTlsConfig extends Message<PackageRepositoryTlsConfig> {
  /**
   * whether or not to skip TLS verification
   * note that fluxv2 does not currently support this and will raise an
   * error should this flag be set to true
   *
   * @generated from field: bool insecure_skip_verify = 1;
   */
  insecureSkipVerify = false;

  /**
   * @generated from oneof kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig.package_repo_tls_config_one_of
   */
  packageRepoTlsConfigOneOf:
    | {
        /**
         * certificate authority. Optional
         *
         * @generated from field: string cert_authority = 2;
         */
        value: string;
        case: "certAuthority";
      }
    | {
        /**
         * a reference to an existing secret that contains custom CA
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.SecretKeyReference secret_ref = 3;
         */
        value: SecretKeyReference;
        case: "secretRef";
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PackageRepositoryTlsConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insecure_skip_verify", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    {
      no: 2,
      name: "cert_authority",
      kind: "scalar",
      T: 9 /* ScalarType.STRING */,
      oneof: "package_repo_tls_config_one_of",
    },
    {
      no: 3,
      name: "secret_ref",
      kind: "message",
      T: SecretKeyReference,
      oneof: "package_repo_tls_config_one_of",
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoryTlsConfig {
    return new PackageRepositoryTlsConfig().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryTlsConfig {
    return new PackageRepositoryTlsConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryTlsConfig {
    return new PackageRepositoryTlsConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoryTlsConfig | PlainMessage<PackageRepositoryTlsConfig> | undefined,
    b: PackageRepositoryTlsConfig | PlainMessage<PackageRepositoryTlsConfig> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoryTlsConfig, a, b);
  }
}

/**
 * PackageRepositoryAuth
 *
 * Authentication/authorization to provide client's identity when connecting
 * to a package repository.
 * There are 6 total distinct use cases we may support:
 * 1) None (Public)
 * 2) Basic Auth
 * 3) Bearer Token
 * 4) Custom Authorization Header
 * 5) Docker Registry Credentials (for OCI only)
 * 6) TLS certificate and key
 *
 * Note that (1)-(4) may be done over HTTP or HTTPs without any custom
 * certificates or certificate authority
 * (1) is handled by not not having PackageRepositoryAuth field on
 *     the parent object
 * a given plug-in may or may not support a given authentication type.
 * For example
 *  - direct-helm plug-in does not currently support (6), while flux does
 *  - flux plug-in does not support (3) or (4) while direct-helm does
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth
 */
export class PackageRepositoryAuth extends Message<PackageRepositoryAuth> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth.PackageRepositoryAuthType type = 1;
   */
  type = PackageRepositoryAuth_PackageRepositoryAuthType.UNSPECIFIED;

  /**
   * @generated from oneof kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth.package_repo_auth_one_of
   */
  packageRepoAuthOneOf:
    | {
        /**
         * username and plain text password
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.UsernamePassword username_password = 2;
         */
        value: UsernamePassword;
        case: "usernamePassword";
      }
    | {
        /**
         * certificate and key for TLS-based authentication
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.TlsCertKey tls_cert_key = 3;
         */
        value: TlsCertKey;
        case: "tlsCertKey";
      }
    | {
        /**
         * docker credentials
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.DockerCredentials docker_creds = 4;
         */
        value: DockerCredentials;
        case: "dockerCreds";
      }
    | {
        /**
         * for Bearer Auth token value
         * for Custom Auth, complete value of "Authorization" header
         *
         * @generated from field: string header = 5;
         */
        value: string;
        case: "header";
      }
    | {
        /**
         * a reference to an existing secret
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.SecretKeyReference secret_ref = 6;
         */
        value: SecretKeyReference;
        case: "secretRef";
      }
    | {
        /**
         * SSH credentials
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.SshCredentials ssh_creds = 8;
         */
        value: SshCredentials;
        case: "sshCreds";
      }
    | {
        /**
         * opaque credentials
         *
         * @generated from field: kubeappsapis.core.packages.v1alpha1.OpaqueCredentials opaque_creds = 9;
         */
        value: OpaqueCredentials;
        case: "opaqueCreds";
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * pass_credentials allows the credentials from the SecretRef to be passed
   * on to a host that does not match the host as defined in URL.
   * This flag controls whether or not it is allowed to passing credentials
   * with requests to other domains linked from the repository.
   * This may be needed if the host of the advertised chart URLs in the
   * index differs from the defined URL. Optional
   *
   * @generated from field: bool pass_credentials = 7;
   */
  passCredentials = false;

  constructor(data?: PartialMessage<PackageRepositoryAuth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "type",
      kind: "enum",
      T: proto3.getEnumType(PackageRepositoryAuth_PackageRepositoryAuthType),
    },
    {
      no: 2,
      name: "username_password",
      kind: "message",
      T: UsernamePassword,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 3,
      name: "tls_cert_key",
      kind: "message",
      T: TlsCertKey,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 4,
      name: "docker_creds",
      kind: "message",
      T: DockerCredentials,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 5,
      name: "header",
      kind: "scalar",
      T: 9 /* ScalarType.STRING */,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 6,
      name: "secret_ref",
      kind: "message",
      T: SecretKeyReference,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 8,
      name: "ssh_creds",
      kind: "message",
      T: SshCredentials,
      oneof: "package_repo_auth_one_of",
    },
    {
      no: 9,
      name: "opaque_creds",
      kind: "message",
      T: OpaqueCredentials,
      oneof: "package_repo_auth_one_of",
    },
    { no: 7, name: "pass_credentials", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoryAuth {
    return new PackageRepositoryAuth().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackageRepositoryAuth {
    return new PackageRepositoryAuth().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryAuth {
    return new PackageRepositoryAuth().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoryAuth | PlainMessage<PackageRepositoryAuth> | undefined,
    b: PackageRepositoryAuth | PlainMessage<PackageRepositoryAuth> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoryAuth, a, b);
  }
}

/**
 * @generated from enum kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth.PackageRepositoryAuthType
 */
export enum PackageRepositoryAuth_PackageRepositoryAuthType {
  /**
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * uses UsernamePassword
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_BASIC_AUTH = 1;
   */
  BASIC_AUTH = 1,

  /**
   * uses TlsCertKey
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_TLS = 2;
   */
  TLS = 2,

  /**
   * uses header
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_BEARER = 3;
   */
  BEARER = 3,

  /**
   * uses header
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_AUTHORIZATION_HEADER = 4;
   */
  AUTHORIZATION_HEADER = 4,

  /**
   * uses DockerCredentials
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_DOCKER_CONFIG_JSON = 5;
   */
  DOCKER_CONFIG_JSON = 5,

  /**
   * uses SshCredentials
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_SSH = 6;
   */
  SSH = 6,

  /**
   * uses OpaqueCredentials
   *
   * @generated from enum value: PACKAGE_REPOSITORY_AUTH_TYPE_OPAQUE = 7;
   */
  OPAQUE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(PackageRepositoryAuth_PackageRepositoryAuthType)
proto3.util.setEnumType(
  PackageRepositoryAuth_PackageRepositoryAuthType,
  "kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth.PackageRepositoryAuthType",
  [
    { no: 0, name: "PACKAGE_REPOSITORY_AUTH_TYPE_UNSPECIFIED" },
    { no: 1, name: "PACKAGE_REPOSITORY_AUTH_TYPE_BASIC_AUTH" },
    { no: 2, name: "PACKAGE_REPOSITORY_AUTH_TYPE_TLS" },
    { no: 3, name: "PACKAGE_REPOSITORY_AUTH_TYPE_BEARER" },
    { no: 4, name: "PACKAGE_REPOSITORY_AUTH_TYPE_AUTHORIZATION_HEADER" },
    { no: 5, name: "PACKAGE_REPOSITORY_AUTH_TYPE_DOCKER_CONFIG_JSON" },
    { no: 6, name: "PACKAGE_REPOSITORY_AUTH_TYPE_SSH" },
    { no: 7, name: "PACKAGE_REPOSITORY_AUTH_TYPE_OPAQUE" },
  ],
);

/**
 * UsernamePassword
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.UsernamePassword
 */
export class UsernamePassword extends Message<UsernamePassword> {
  /**
   * In clear text
   *
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * In clear text
   *
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<UsernamePassword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.UsernamePassword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsernamePassword {
    return new UsernamePassword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsernamePassword {
    return new UsernamePassword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsernamePassword {
    return new UsernamePassword().fromJsonString(jsonString, options);
  }

  static equals(
    a: UsernamePassword | PlainMessage<UsernamePassword> | undefined,
    b: UsernamePassword | PlainMessage<UsernamePassword> | undefined,
  ): boolean {
    return proto3.util.equals(UsernamePassword, a, b);
  }
}

/**
 * TlsCertKey
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.TlsCertKey
 */
export class TlsCertKey extends Message<TlsCertKey> {
  /**
   * certificate (identity). In clear text
   *
   * @generated from field: string cert = 1;
   */
  cert = "";

  /**
   * certificate key. In clear text
   *
   * @generated from field: string key = 2;
   */
  key = "";

  constructor(data?: PartialMessage<TlsCertKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.TlsCertKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cert", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TlsCertKey {
    return new TlsCertKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TlsCertKey {
    return new TlsCertKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TlsCertKey {
    return new TlsCertKey().fromJsonString(jsonString, options);
  }

  static equals(
    a: TlsCertKey | PlainMessage<TlsCertKey> | undefined,
    b: TlsCertKey | PlainMessage<TlsCertKey> | undefined,
  ): boolean {
    return proto3.util.equals(TlsCertKey, a, b);
  }
}

/**
 * DockerCredentials
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.DockerCredentials
 */
export class DockerCredentials extends Message<DockerCredentials> {
  /**
   * server name
   *
   * @generated from field: string server = 1;
   */
  server = "";

  /**
   * username.
   *
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * password. In clear text
   *
   * @generated from field: string password = 3;
   */
  password = "";

  /**
   * email address
   *
   * @generated from field: string email = 4;
   */
  email = "";

  constructor(data?: PartialMessage<DockerCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.DockerCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "server", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DockerCredentials {
    return new DockerCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DockerCredentials {
    return new DockerCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DockerCredentials {
    return new DockerCredentials().fromJsonString(jsonString, options);
  }

  static equals(
    a: DockerCredentials | PlainMessage<DockerCredentials> | undefined,
    b: DockerCredentials | PlainMessage<DockerCredentials> | undefined,
  ): boolean {
    return proto3.util.equals(DockerCredentials, a, b);
  }
}

/**
 * SshCredentials
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.SshCredentials
 */
export class SshCredentials extends Message<SshCredentials> {
  /**
   * private key
   *
   * @generated from field: string private_key = 1;
   */
  privateKey = "";

  /**
   * known hosts.
   *
   * @generated from field: string known_hosts = 2;
   */
  knownHosts = "";

  constructor(data?: PartialMessage<SshCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.SshCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "private_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "known_hosts", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SshCredentials {
    return new SshCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SshCredentials {
    return new SshCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SshCredentials {
    return new SshCredentials().fromJsonString(jsonString, options);
  }

  static equals(
    a: SshCredentials | PlainMessage<SshCredentials> | undefined,
    b: SshCredentials | PlainMessage<SshCredentials> | undefined,
  ): boolean {
    return proto3.util.equals(SshCredentials, a, b);
  }
}

/**
 * OpaqueCredentials
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.OpaqueCredentials
 */
export class OpaqueCredentials extends Message<OpaqueCredentials> {
  /**
   * fields
   *
   * @generated from field: map<string, string> data = 1;
   */
  data: { [key: string]: string } = {};

  constructor(data?: PartialMessage<OpaqueCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.OpaqueCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "data",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpaqueCredentials {
    return new OpaqueCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpaqueCredentials {
    return new OpaqueCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpaqueCredentials {
    return new OpaqueCredentials().fromJsonString(jsonString, options);
  }

  static equals(
    a: OpaqueCredentials | PlainMessage<OpaqueCredentials> | undefined,
    b: OpaqueCredentials | PlainMessage<OpaqueCredentials> | undefined,
  ): boolean {
    return proto3.util.equals(OpaqueCredentials, a, b);
  }
}

/**
 * SecretKeyReference
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.SecretKeyReference
 */
export class SecretKeyReference extends Message<SecretKeyReference> {
  /**
   * The name of an existing secret in the same namespace as the object
   * that refers to it (e.g. PackageRepository), containing authentication
   * credentials for the said package repository.
   * - For HTTP/S basic auth the secret must be of type
   *   "kubernetes.io/basic-auth" or opaque and contain username and
   *   password fields
   * - For TLS the secret must be of type "kubernetes.io/tls" or opaque
   *   and contain a certFile and keyFile, and/or
   *   caCert fields.
   * - For Bearer or Custom Auth, the secret must be opaque, and
   *   the key must be provided
   * - For Docker Registry Credentials (OCI registries) the secret
   *   must of of type "kubernetes.io/dockerconfigjson"
   * For more details, refer to
   * https://kubernetes.io/docs/concepts/configuration/secret/
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Optional. Must be provided when name refers to an opaque secret
   *
   * @generated from field: string key = 2;
   */
  key = "";

  constructor(data?: PartialMessage<SecretKeyReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.SecretKeyReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SecretKeyReference {
    return new SecretKeyReference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SecretKeyReference {
    return new SecretKeyReference().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): SecretKeyReference {
    return new SecretKeyReference().fromJsonString(jsonString, options);
  }

  static equals(
    a: SecretKeyReference | PlainMessage<SecretKeyReference> | undefined,
    b: SecretKeyReference | PlainMessage<SecretKeyReference> | undefined,
  ): boolean {
    return proto3.util.equals(SecretKeyReference, a, b);
  }
}

/**
 * GetPackageRepositoryDetailRequest
 *
 * Request for GetPackageRepositoryDetail
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryDetailRequest
 */
export class GetPackageRepositoryDetailRequest extends Message<GetPackageRepositoryDetailRequest> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  constructor(data?: PartialMessage<GetPackageRepositoryDetailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryDetailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositoryDetailRequest {
    return new GetPackageRepositoryDetailRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryDetailRequest {
    return new GetPackageRepositoryDetailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryDetailRequest {
    return new GetPackageRepositoryDetailRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositoryDetailRequest
      | PlainMessage<GetPackageRepositoryDetailRequest>
      | undefined,
    b:
      | GetPackageRepositoryDetailRequest
      | PlainMessage<GetPackageRepositoryDetailRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositoryDetailRequest, a, b);
  }
}

/**
 * GetPackageRepositorySummariesRequest
 *
 * Request for PackageRepositorySummary
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositorySummariesRequest
 */
export class GetPackageRepositorySummariesRequest extends Message<GetPackageRepositorySummariesRequest> {
  /**
   * The context (cluster/namespace) for the request.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  constructor(data?: PartialMessage<GetPackageRepositorySummariesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositorySummariesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositorySummariesRequest {
    return new GetPackageRepositorySummariesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositorySummariesRequest {
    return new GetPackageRepositorySummariesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositorySummariesRequest {
    return new GetPackageRepositorySummariesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositorySummariesRequest
      | PlainMessage<GetPackageRepositorySummariesRequest>
      | undefined,
    b:
      | GetPackageRepositorySummariesRequest
      | PlainMessage<GetPackageRepositorySummariesRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositorySummariesRequest, a, b);
  }
}

/**
 * UpdatePackageRepositoryRequest
 *
 * Request for UpdatePackageRepository
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.UpdatePackageRepositoryRequest
 */
export class UpdatePackageRepositoryRequest extends Message<UpdatePackageRepositoryRequest> {
  /**
   * A reference uniquely identifying the package repository being updated.
   * The only required field
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  /**
   * URL identifying the package repository location.
   *
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * A user-provided description.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * The interval at which to check the upstream for updates (in time+unit)
   * Some plugins may, additionally, support other formats, for instance,
   * a cron expression.
   * e.g., "*\/10 * * * *" will be equivalent to "10m"
   * Optional. Defaults to 10m if not specified
   *
   * @generated from field: string interval = 4;
   */
  interval = "";

  /**
   * TLS-specific parameters for connecting to a repository. Optional
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig tls_config = 5;
   */
  tlsConfig?: PackageRepositoryTlsConfig;

  /**
   * authentication parameters for connecting to a repository. Optional
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth auth = 6;
   */
  auth?: PackageRepositoryAuth;

  /**
   * Custom data added by the plugin
   * A plugin can define custom details for data which is not yet, or
   * never will be specified in the core AddPackageRepositoryRequest
   * fields. The use of an `Any` field means that each plugin can define
   * the structure of this message as required, while still satisfying the
   * core interface.
   * See https://developers.google.com/protocol-buffers/docs/proto3#any
   * Just for reference, some of the examples that have been chosen not to
   * be part of the core API but rather plugin-specific details are:
   *   direct-helm:
   *      - image pull secrets
   *      - list of oci repositories
   *      - filter rules
   *      - sync job pod template
   *
   * @generated from field: google.protobuf.Any custom_detail = 11;
   */
  customDetail?: Any;

  constructor(data?: PartialMessage<UpdatePackageRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.UpdatePackageRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "interval", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tls_config", kind: "message", T: PackageRepositoryTlsConfig },
    { no: 6, name: "auth", kind: "message", T: PackageRepositoryAuth },
    { no: 11, name: "custom_detail", kind: "message", T: Any },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdatePackageRepositoryRequest {
    return new UpdatePackageRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdatePackageRepositoryRequest {
    return new UpdatePackageRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdatePackageRepositoryRequest {
    return new UpdatePackageRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdatePackageRepositoryRequest | PlainMessage<UpdatePackageRepositoryRequest> | undefined,
    b: UpdatePackageRepositoryRequest | PlainMessage<UpdatePackageRepositoryRequest> | undefined,
  ): boolean {
    return proto3.util.equals(UpdatePackageRepositoryRequest, a, b);
  }
}

/**
 * DeletePackageRepositoryRequest
 *
 * Request for DeletePackageRepository
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.DeletePackageRepositoryRequest
 */
export class DeletePackageRepositoryRequest extends Message<DeletePackageRepositoryRequest> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  constructor(data?: PartialMessage<DeletePackageRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.DeletePackageRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeletePackageRepositoryRequest {
    return new DeletePackageRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeletePackageRepositoryRequest {
    return new DeletePackageRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeletePackageRepositoryRequest {
    return new DeletePackageRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeletePackageRepositoryRequest | PlainMessage<DeletePackageRepositoryRequest> | undefined,
    b: DeletePackageRepositoryRequest | PlainMessage<DeletePackageRepositoryRequest> | undefined,
  ): boolean {
    return proto3.util.equals(DeletePackageRepositoryRequest, a, b);
  }
}

/**
 * PackageRepositoryReference
 *
 * A PackageRepositoryReference has the minimum information required to
 * uniquely identify a package repository.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference
 */
export class PackageRepositoryReference extends Message<PackageRepositoryReference> {
  /**
   * The context (cluster/namespace) for the repository.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  /**
   * The fully qualified identifier for the repository
   * (i.e. a unique name for the context).
   *
   * @generated from field: string identifier = 2;
   */
  identifier = "";

  /**
   * The plugin used to interact with this available package.
   * This field should be omitted when the request is in the context of a
   * specific plugin.
   *
   * @generated from field: kubeappsapis.core.plugins.v1alpha1.Plugin plugin = 3;
   */
  plugin?: Plugin;

  constructor(data?: PartialMessage<PackageRepositoryReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
    { no: 2, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plugin", kind: "message", T: Plugin },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoryReference {
    return new PackageRepositoryReference().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryReference {
    return new PackageRepositoryReference().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryReference {
    return new PackageRepositoryReference().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoryReference | PlainMessage<PackageRepositoryReference> | undefined,
    b: PackageRepositoryReference | PlainMessage<PackageRepositoryReference> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoryReference, a, b);
  }
}

/**
 * AddPackageRepositoryResponse
 *
 * Response for AddPackageRepositoryRequest
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.AddPackageRepositoryResponse
 */
export class AddPackageRepositoryResponse extends Message<AddPackageRepositoryResponse> {
  /**
   * TODO: add example for API docs
   * option (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema) = {
   *   example: '{"package_repo_ref": {}}'
   * };
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  constructor(data?: PartialMessage<AddPackageRepositoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.AddPackageRepositoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AddPackageRepositoryResponse {
    return new AddPackageRepositoryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AddPackageRepositoryResponse {
    return new AddPackageRepositoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AddPackageRepositoryResponse {
    return new AddPackageRepositoryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddPackageRepositoryResponse | PlainMessage<AddPackageRepositoryResponse> | undefined,
    b: AddPackageRepositoryResponse | PlainMessage<AddPackageRepositoryResponse> | undefined,
  ): boolean {
    return proto3.util.equals(AddPackageRepositoryResponse, a, b);
  }
}

/**
 * PackageRepositoryStatus
 *
 * A PackageRepositoryStatus reports on the current status of the repository.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus
 */
export class PackageRepositoryStatus extends Message<PackageRepositoryStatus> {
  /**
   * Ready
   *
   * An indication of whether the repository is ready or not
   *
   * @generated from field: bool ready = 1;
   */
  ready = false;

  /**
   * Reason
   *
   * An enum indicating the reason for the current status.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus.StatusReason reason = 2;
   */
  reason = PackageRepositoryStatus_StatusReason.UNSPECIFIED;

  /**
   * UserReason
   *
   * Optional text to return for user context, which may be plugin specific.
   *
   * @generated from field: string user_reason = 3;
   */
  userReason = "";

  constructor(data?: PartialMessage<PackageRepositoryStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ready", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    {
      no: 2,
      name: "reason",
      kind: "enum",
      T: proto3.getEnumType(PackageRepositoryStatus_StatusReason),
    },
    { no: 3, name: "user_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoryStatus {
    return new PackageRepositoryStatus().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryStatus {
    return new PackageRepositoryStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryStatus {
    return new PackageRepositoryStatus().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoryStatus | PlainMessage<PackageRepositoryStatus> | undefined,
    b: PackageRepositoryStatus | PlainMessage<PackageRepositoryStatus> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoryStatus, a, b);
  }
}

/**
 * StatusReason
 *
 * Generic reasons why a package repository may be ready or not.
 * These should make sense across different packaging plugins.
 *
 * @generated from enum kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus.StatusReason
 */
export enum PackageRepositoryStatus_StatusReason {
  /**
   * @generated from enum value: STATUS_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_REASON_SUCCESS = 1;
   */
  SUCCESS = 1,

  /**
   * @generated from enum value: STATUS_REASON_FAILED = 2;
   */
  FAILED = 2,

  /**
   * @generated from enum value: STATUS_REASON_PENDING = 3;
   */
  PENDING = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PackageRepositoryStatus_StatusReason)
proto3.util.setEnumType(
  PackageRepositoryStatus_StatusReason,
  "kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus.StatusReason",
  [
    { no: 0, name: "STATUS_REASON_UNSPECIFIED" },
    { no: 1, name: "STATUS_REASON_SUCCESS" },
    { no: 2, name: "STATUS_REASON_FAILED" },
    { no: 3, name: "STATUS_REASON_PENDING" },
  ],
);

/**
 * PackageRepositoryDetail
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoryDetail
 */
export class PackageRepositoryDetail extends Message<PackageRepositoryDetail> {
  /**
   * A reference uniquely identifying the package repository.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  /**
   * A user-provided name for the package repository (e.g. bitnami)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * A user-provided description.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * Whether this repository is global or namespace-scoped.
   *
   * @generated from field: bool namespace_scoped = 4;
   */
  namespaceScoped = false;

  /**
   * Package storage type
   *
   * @generated from field: string type = 5;
   */
  type = "";

  /**
   * A URL identifying the package repository location.
   *
   * @generated from field: string url = 6;
   */
  url = "";

  /**
   * The interval at which to check the upstream for updates (in time+unit)
   * Some plugins may, additionally, support other formats, for instance,
   * a cron expression.
   * e.g., "*\/10 * * * *" will be equivalent to "10m"
   *
   * @generated from field: string interval = 7;
   */
  interval = "";

  /**
   * TLS-specific parameters for connecting to a repository.
   * If the cert authority was configured for this repository, then in the context
   * of GetPackageRepositoryDetail() operation, the PackageRepositoryTlsConfig will ALWAYS
   * contain an existing SecretKeyReference, rather that cert_authority field
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryTlsConfig tls_config = 8;
   */
  tlsConfig?: PackageRepositoryTlsConfig;

  /**
   * authentication parameters for connecting to a repository.
   * If Basic Auth or TLS or Docker Creds Auth was configured for this repository,
   * then in the context of GetPackageRepositoryDetail() operation, the
   * PackageRepositoryAuth will ALWAYS contain an existing SecretKeyReference,
   * rather that string values that may have been used when package repository was created
   * field
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryAuth auth = 9;
   */
  auth?: PackageRepositoryAuth;

  /**
   * Custom data added by the plugin
   *
   * @generated from field: google.protobuf.Any custom_detail = 10;
   */
  customDetail?: Any;

  /**
   * current status of the repository which can include reconciliation
   * status, where relevant.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus status = 11;
   */
  status?: PackageRepositoryStatus;

  constructor(data?: PartialMessage<PackageRepositoryDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoryDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "namespace_scoped", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "interval", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "tls_config", kind: "message", T: PackageRepositoryTlsConfig },
    { no: 9, name: "auth", kind: "message", T: PackageRepositoryAuth },
    { no: 10, name: "custom_detail", kind: "message", T: Any },
    { no: 11, name: "status", kind: "message", T: PackageRepositoryStatus },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoryDetail {
    return new PackageRepositoryDetail().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryDetail {
    return new PackageRepositoryDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoryDetail {
    return new PackageRepositoryDetail().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoryDetail | PlainMessage<PackageRepositoryDetail> | undefined,
    b: PackageRepositoryDetail | PlainMessage<PackageRepositoryDetail> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoryDetail, a, b);
  }
}

/**
 * GetPackageRepositoryDetailResponse
 *
 * Response for GetPackageRepositoryDetail
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryDetailResponse
 */
export class GetPackageRepositoryDetailResponse extends Message<GetPackageRepositoryDetailResponse> {
  /**
   * package repository detail
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryDetail detail = 1;
   */
  detail?: PackageRepositoryDetail;

  constructor(data?: PartialMessage<GetPackageRepositoryDetailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryDetailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "detail", kind: "message", T: PackageRepositoryDetail },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositoryDetailResponse {
    return new GetPackageRepositoryDetailResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryDetailResponse {
    return new GetPackageRepositoryDetailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryDetailResponse {
    return new GetPackageRepositoryDetailResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositoryDetailResponse
      | PlainMessage<GetPackageRepositoryDetailResponse>
      | undefined,
    b:
      | GetPackageRepositoryDetailResponse
      | PlainMessage<GetPackageRepositoryDetailResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositoryDetailResponse, a, b);
  }
}

/**
 * PackageRepositorySummary
 *
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositorySummary
 */
export class PackageRepositorySummary extends Message<PackageRepositorySummary> {
  /**
   * A reference uniquely identifying the package repository.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  /**
   * A user-provided name for the package repository (e.g. bitnami)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * A user-provided description.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * Whether this repository is global or namespace-scoped.
   *
   * @generated from field: bool namespace_scoped = 4;
   */
  namespaceScoped = false;

  /**
   * Package storage type
   *
   * @generated from field: string type = 5;
   */
  type = "";

  /**
   * URL identifying the package repository location.
   *
   * @generated from field: string url = 6;
   */
  url = "";

  /**
   * current status of the repository which can include reconciliation
   * status, where relevant.
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryStatus status = 7;
   */
  status?: PackageRepositoryStatus;

  /**
   * existence of any authentication parameters for connecting to a repository.
   *
   * @generated from field: bool requires_auth = 8;
   */
  requiresAuth = false;

  constructor(data?: PartialMessage<PackageRepositorySummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositorySummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "namespace_scoped", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "message", T: PackageRepositoryStatus },
    { no: 8, name: "requires_auth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositorySummary {
    return new PackageRepositorySummary().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositorySummary {
    return new PackageRepositorySummary().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositorySummary {
    return new PackageRepositorySummary().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositorySummary | PlainMessage<PackageRepositorySummary> | undefined,
    b: PackageRepositorySummary | PlainMessage<PackageRepositorySummary> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositorySummary, a, b);
  }
}

/**
 * GetPackageRepositorySummariesResponse
 *
 * Response for GetPackageRepositorySummaries
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositorySummariesResponse
 */
export class GetPackageRepositorySummariesResponse extends Message<GetPackageRepositorySummariesResponse> {
  /**
   * List of PackageRepositorySummary
   *
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.PackageRepositorySummary package_repository_summaries = 1;
   */
  packageRepositorySummaries: PackageRepositorySummary[] = [];

  constructor(data?: PartialMessage<GetPackageRepositorySummariesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositorySummariesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "package_repository_summaries",
      kind: "message",
      T: PackageRepositorySummary,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositorySummariesResponse {
    return new GetPackageRepositorySummariesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositorySummariesResponse {
    return new GetPackageRepositorySummariesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositorySummariesResponse {
    return new GetPackageRepositorySummariesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositorySummariesResponse
      | PlainMessage<GetPackageRepositorySummariesResponse>
      | undefined,
    b:
      | GetPackageRepositorySummariesResponse
      | PlainMessage<GetPackageRepositorySummariesResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositorySummariesResponse, a, b);
  }
}

/**
 * UpdatePackageRepositoryResponse
 *
 * Response for UpdatePackageRepository
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.UpdatePackageRepositoryResponse
 */
export class UpdatePackageRepositoryResponse extends Message<UpdatePackageRepositoryResponse> {
  /**
   * @generated from field: kubeappsapis.core.packages.v1alpha1.PackageRepositoryReference package_repo_ref = 1;
   */
  packageRepoRef?: PackageRepositoryReference;

  constructor(data?: PartialMessage<UpdatePackageRepositoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.UpdatePackageRepositoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_repo_ref", kind: "message", T: PackageRepositoryReference },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdatePackageRepositoryResponse {
    return new UpdatePackageRepositoryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdatePackageRepositoryResponse {
    return new UpdatePackageRepositoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdatePackageRepositoryResponse {
    return new UpdatePackageRepositoryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdatePackageRepositoryResponse | PlainMessage<UpdatePackageRepositoryResponse> | undefined,
    b: UpdatePackageRepositoryResponse | PlainMessage<UpdatePackageRepositoryResponse> | undefined,
  ): boolean {
    return proto3.util.equals(UpdatePackageRepositoryResponse, a, b);
  }
}

/**
 * DeletePackageRepositoryResponse
 *
 * Response for DeletePackageRepository
 *
 * For future extensibility only.
 *
 * @generated from message kubeappsapis.core.packages.v1alpha1.DeletePackageRepositoryResponse
 */
export class DeletePackageRepositoryResponse extends Message<DeletePackageRepositoryResponse> {
  constructor(data?: PartialMessage<DeletePackageRepositoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.DeletePackageRepositoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeletePackageRepositoryResponse {
    return new DeletePackageRepositoryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeletePackageRepositoryResponse {
    return new DeletePackageRepositoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeletePackageRepositoryResponse {
    return new DeletePackageRepositoryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeletePackageRepositoryResponse | PlainMessage<DeletePackageRepositoryResponse> | undefined,
    b: DeletePackageRepositoryResponse | PlainMessage<DeletePackageRepositoryResponse> | undefined,
  ): boolean {
    return proto3.util.equals(DeletePackageRepositoryResponse, a, b);
  }
}

/**
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryPermissionsRequest
 */
export class GetPackageRepositoryPermissionsRequest extends Message<GetPackageRepositoryPermissionsRequest> {
  /**
   * The context (cluster/namespace) for the request
   *
   * @generated from field: kubeappsapis.core.packages.v1alpha1.Context context = 1;
   */
  context?: Context;

  constructor(data?: PartialMessage<GetPackageRepositoryPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryPermissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context", kind: "message", T: Context },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositoryPermissionsRequest {
    return new GetPackageRepositoryPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryPermissionsRequest {
    return new GetPackageRepositoryPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryPermissionsRequest {
    return new GetPackageRepositoryPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositoryPermissionsRequest
      | PlainMessage<GetPackageRepositoryPermissionsRequest>
      | undefined,
    b:
      | GetPackageRepositoryPermissionsRequest
      | PlainMessage<GetPackageRepositoryPermissionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositoryPermissionsRequest, a, b);
  }
}

/**
 * @generated from message kubeappsapis.core.packages.v1alpha1.PackageRepositoriesPermissions
 */
export class PackageRepositoriesPermissions extends Message<PackageRepositoriesPermissions> {
  /**
   * @generated from field: kubeappsapis.core.plugins.v1alpha1.Plugin plugin = 1;
   */
  plugin?: Plugin;

  /**
   * Permissions at the global namespace (if any)
   *
   * @generated from field: map<string, bool> global = 2;
   */
  global: { [key: string]: boolean } = {};

  /**
   * Permissions for the namespace
   *
   * @generated from field: map<string, bool> namespace = 3;
   */
  namespace: { [key: string]: boolean } = {};

  constructor(data?: PartialMessage<PackageRepositoriesPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kubeappsapis.core.packages.v1alpha1.PackageRepositoriesPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plugin", kind: "message", T: Plugin },
    {
      no: 2,
      name: "global",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    },
    {
      no: 3,
      name: "namespace",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PackageRepositoriesPermissions {
    return new PackageRepositoriesPermissions().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoriesPermissions {
    return new PackageRepositoriesPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PackageRepositoriesPermissions {
    return new PackageRepositoriesPermissions().fromJsonString(jsonString, options);
  }

  static equals(
    a: PackageRepositoriesPermissions | PlainMessage<PackageRepositoriesPermissions> | undefined,
    b: PackageRepositoriesPermissions | PlainMessage<PackageRepositoriesPermissions> | undefined,
  ): boolean {
    return proto3.util.equals(PackageRepositoriesPermissions, a, b);
  }
}

/**
 * @generated from message kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryPermissionsResponse
 */
export class GetPackageRepositoryPermissionsResponse extends Message<GetPackageRepositoryPermissionsResponse> {
  /**
   * @generated from field: repeated kubeappsapis.core.packages.v1alpha1.PackageRepositoriesPermissions permissions = 1;
   */
  permissions: PackageRepositoriesPermissions[] = [];

  constructor(data?: PartialMessage<GetPackageRepositoryPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName =
    "kubeappsapis.core.packages.v1alpha1.GetPackageRepositoryPermissionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "permissions",
      kind: "message",
      T: PackageRepositoriesPermissions,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetPackageRepositoryPermissionsResponse {
    return new GetPackageRepositoryPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryPermissionsResponse {
    return new GetPackageRepositoryPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetPackageRepositoryPermissionsResponse {
    return new GetPackageRepositoryPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetPackageRepositoryPermissionsResponse
      | PlainMessage<GetPackageRepositoryPermissionsResponse>
      | undefined,
    b:
      | GetPackageRepositoryPermissionsResponse
      | PlainMessage<GetPackageRepositoryPermissionsResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetPackageRepositoryPermissionsResponse, a, b);
  }
}
